/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.common.navigation').controller('EditAccountDialogController', ['EnumService', 'loginService', '$mdDialog', 'NotificationService', '$state', 'UserResourceFactory', function (EnumService, loginService, $mdDialog, NotificationService, $state, UserResourceFactory) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.userId = null;
    vm.user = null;
    vm.sessionData = null;
    vm.states = [];
    vm.countries = [];
    vm.originalUser = null;
    vm.provinces = [];
    vm.jobClassifications = [];
    /* BINDABLE : METHODS*/

    vm.cancel = cancel;
    vm.changePassword = changePassword;
    vm.disableSave = disableSave;
    vm.save = save;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function cancel() {
      $mdDialog.cancel();
    }

    function changePassword() {
      $mdDialog.show({
        controller: 'ChangePasswordDialogController',
        controllerAs: 'vm',
        template: require('./changePasswordDialog-template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        fullscreen: true,
        multiple: true,
        locals: {}
      });
    }

    function disableSave() {
      //Disables save if the form has not been modified,
      if (vm.user) {
        return !(vm.user.userName !== '' && !angular.equals(vm.user, vm.originalUser));
      } else {
        return true;
      }
    }

    function save() {
      _formatAddress(); //Set the province as empty if the user has the US selected, and vice versa


      vm.user.province = vm.user.country === 'US' ? undefined : vm.user.province;
      vm.user.state = vm.user.country === 'CA' ? undefined : vm.user.state;
      UserResourceFactory.save({
        id: vm.user.id
      }, vm.user, function (user) {
        NotificationService.showNotificationToast('Successfully updated account details');
      });
      $mdDialog.hide();
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _formatAddress() {
      //Deal with the address
      vm.user.address = '';

      if (vm.user.address1) {
        vm.user.address += vm.user.address1;
      }

      if (vm.user.address2) {
        vm.user.address += '\n' + vm.user.address2;
      }
    }

    function _init() {
      vm.sessionData = loginService.getSessionData();
      vm.userId = vm.sessionData.id;
      UserResourceFactory.get({
        id: vm.userId
      }, function (user) {
        vm.user = user;

        if (vm.user.address) {
          var addresses = vm.user.address.split('\n');
          vm.user.address1 = addresses[0];
          vm.user.address2 = addresses.length === 2 ? addresses[1] : '';
        }

        vm.originalUser = angular.copy(vm.user);
      });
      UserResourceFactory.getJobClassifications({}, function (classifications) {
        vm.jobClassifications = classifications.data;
      }, function () {
        vm.jobClassifications = [];
      });
      EnumService.getEnum('SecurityUser', 'state').then(function (states) {
        vm.states = states;
      }, function () {
        vm.states = [];
      });
      EnumService.getEnum('SecurityUser', 'province').then(function (provinces) {
        vm.provinces = provinces;
      }, function () {
        vm.provinces = [];
      });
      EnumService.getEnum('SecurityUser', 'country').then(function (countries) {
        vm.countries = countries;
      }, function () {
        vm.countries = [];
      });
    }
  }]);
})();