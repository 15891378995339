/**
 * Created by srober225 on 1/25/17.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.common.navigation').controller('loginNavController', ['$http', 'EndPointService', 'BrandingResourceFactory', 'loginService', function ($http, EndPointService, BrandingResourceFactory, loginService) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.liteBranding = '';
    /* BINDABLE : METHODS*/

    vm.toggleLogo = toggleLogo;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function toggleLogo() {
      return bowser.msie || bowser.msedge;
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      //TODO: Initialize this controller
      BrandingResourceFactory.getLite({}, function (brand) {
        try {
          //success
          vm.liteBranding = brand && brand.image ? brand.image.data : '';
        } catch (err) {
          // The branding image came back undefined
          vm.liteBranding = '';
        }

        loginService.brandInfo(brand);
      }, function noImage() {
        vm.liteBranding = '';
      });
    }
  }]);
})();