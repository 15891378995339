/**
 * Created by srober225 on 1/24/17.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.common.navigation').controller('subNavController', ['loginService', function (loginService) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.currentUser = loginService.getSessionData();
    /* BINDABLE : METHODS*/

    vm.openMenu = function ($mdMenu) {
      $mdMenu.open();
    };
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here


    _init();
    /* IMPLEMENTATION : BINDABLE */

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {//TODO: Initialize this controller
    }
  }]);
})();