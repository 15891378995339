/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2018 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */



//// CONTROLLER ////
export class leftNavController {
    // Private

    // Bindable
    public isSettingSelected: boolean = false;
    public currentUser: any;
    public isFranchise: boolean = false;
    public hideDashboards: boolean = true;



    static get $inject() {
        return [
            'loginService',
            '$location',
            'GoogleAnalyticsServiceService'
        ];
    }

    constructor(
        public loginService: any,
        public $location: any,
        public GoogleAnalyticsServiceService : any,
    ) {
        /* BINDABLE: INJECTS */
        Object.assign(this, {
            loginService,
            $location,
        });

        /* BINDABLE : DATA */
        this.currentUser = this.loginService.getSessionData();
        this.currentUser.accountType = this.currentUser.accountType.replace(/_/g, '');
        this.isFranchise = this.currentUser.franchiseCustomer;
        this.hideDashboards = this.currentUser.noDashboards;
    }

    $onInit() {
        let vm = this;

        if (vm.$location.path().indexOf('settings') > -1) {
            vm.isSettingSelected = true;
        }
    }

    openMenu($mdMenu:any) {
        $mdMenu.open();
    }

    hasRole(roles:string) {
        return this.loginService.hasPermission(roles.split(','));
    }

    navPage(num: number){
        let pagenum: number= num;

        switch (pagenum) {
            case 0:    
                this.GoogleAnalyticsServiceService.logPageView("/Dashboard");
                break;
            case 1:
                this.GoogleAnalyticsServiceService.logPageView("/QuickSend");
                break;
            case 2:
                this.GoogleAnalyticsServiceService.logPageView("/Spots");
                break;
            case 3:
                this.GoogleAnalyticsServiceService.logPageView("/Orders");
                break;
            case 4:
                this.GoogleAnalyticsServiceService.logPageView("/Destinations");
                break;
            case 5:
                this.GoogleAnalyticsServiceService.logPageView("/Settings");
                break;
            case 6:
                this.GoogleAnalyticsServiceService.logPageView("/Deliveries");
                break;
            case 7:
                if(!this.isFranchise){
                    this.GoogleAnalyticsServiceService.logPageView("/Promos");
                }else{
                    this.GoogleAnalyticsServiceService.logPageView("/Assets");
                }  
                break;
            case 8:
                this.GoogleAnalyticsServiceService.logPageView("/Submit");
                break;
            case 9:
                this.GoogleAnalyticsServiceService.logPageView("/View");
                break;
            case 10:
                this.GoogleAnalyticsServiceService.logPageView("/Distributed");
                break;
       }
    }
}
