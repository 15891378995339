/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.common.navigation').controller('ChangePasswordDialogController', ['$rootScope', '$scope', '$state', '$mdDialog', 'PasswordResourceFactory', 'NotificationService', '$mdToast', '$timeout', function ($rootScope, $scope, $state, $mdDialog, PasswordResourceFactory, NotificationService, $mdToast, $timeout) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    vm.passwordResourceFactory = new PasswordResourceFactory();
    /* BINDABLE : DATA */

    vm.oldPassword = '';
    vm.newPassword = '';
    vm.repeatPassword = '';
    vm.showError = false;
    vm.incorrectOldPassword = false;
    /* BINDABLE : METHODS*/

    vm.cancel = cancel;
    vm.saveData = saveData;
    vm.checkPasswordRepeat = checkPasswordRepeat;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function saveData() {
      vm.passwordResourceFactory.$changePassword({
        oldPass: vm.oldPassword,
        newPass: vm.newPassword
      }, function () {
        NotificationService.showNotificationToast('Password Changed Successfully!');
        $mdDialog.hide();
      }, function (error) {
        vm.incorrectOldPassword = error.data === 'Invalid password entered';
        var pwErrorMessage;

        if (vm.incorrectOldPassword) {
          pwErrorMessage = 'Incorrect current password.';
          NotificationService.showNotificationToast('Invalid password entered', pwErrorMessage);
        } else {
          pwErrorMessage = error.data + ' Passwords must be at least 8 characters long.';
          NotificationService.showNotificationToast('Invalid password entered', pwErrorMessage);
        }
      });
    }

    function cancel() {
      $mdDialog.cancel();
    }

    function checkPasswordRepeat() {
      if (vm.newPassword !== vm.repeatPassword) {
        $scope.changePasswordForm.repeatPassword.$error.passwordCheck = true;
        vm.showError = true;
      } else {
        $scope.changePasswordForm.repeatPassword.$error.passwordCheck = false;
        $scope.changePasswordForm.$valid = true;
        vm.showError = false;
      }
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      $timeout(function () {
        $rootScope.changePasswordForm = $scope.changePasswordForm;
      });
    }
  }]);
})();