/**
 * Created by srober225 on 1/25/17.
 */
import { AuthConstant } from '../../../constants/auth.constant'; //// CONTROLLER ////

(function () {
  'use strict';

  angular.module('comcast.common.navigation').controller('standardNavController', ['$rootScope', 'AppScopeService', '$state', '$location', '$mdDialog', 'loginService', '$timeout', '$filter', 'BrandingResourceFactory', 'XferClientResourceFactory', '$window', function ($rootScope, appScope, $state, $location, $mdDialog, loginService, $timeout, $filter, BrandingResourceFactory, XferClientResourceFactory, $window) {
    /* PRIVATE : DATA */
    //Declare all private variables here
    var authConstants = new AuthConstant();
    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/

    var vm = this;
    var sessionData = loginService.getSessionData();
    /* BINDABLE : DATA */

    vm.username = sessionData.username;
    vm.accountType = loginService.accountType();
    vm.currentAccount = sessionData.accountName;
    vm.liteAccount = sessionData.liteAccount;
    vm.isValidAdmin = loginService.isValidAdmin();
    vm.authEnums = authConstants;
    vm.destinationType = sessionData.destinationType;
    vm.userType = null;
    vm.displayHeader = '';
    vm.isClicUser = sessionData.clicUser;
    vm.xferClients = [];
    vm.displayStatus = '';
    vm.statusArray = [{
      status: 'offline',
      label: 'Offline'
    }, {
      status: 'online',
      label: 'Online'
    }, {
      status: 'notregistered',
      label: 'Not Registered'
    }, {
      status: 'noconfig',
      label: 'No Config'
    }, {
      status: 'offline path',
      label: 'Offline Path'
    }];
    vm.brandLogo = '';
    vm.displayBrandInfo = false;
    /* BINDABLE : METHODS*/

    vm.editAccount = editAccount;
    vm.openMenu = openMenu;
    vm.openStatusMenu = openStatusMenu;
    vm.openHelp = openHelp;
    vm.openDetail = openDetail;
    vm.logout = logout;
    vm.openAccountSwitcher = openAccountSwitcher;
    vm.toggleLogo = toggleLogo;
    vm.labelStatus = labelStatus;
    vm.displayTime = displayTime;
    vm.openSupport = openSupport;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function editAccount() {
      $mdDialog.show({
        controller: 'EditAccountDialogController',
        controllerAs: 'vm',
        template: require('./editAccount-template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        fullscreen: true,
        locals: {}
      });
    }

    function openMenu($mdMenu) {
      $mdMenu.open();
    }

    function openStatusMenu($mdMenu) {
      XferClientResourceFactory.getAll({
        accountId: sessionData.accountId
      }, {}, function success(items) {
        // ignore any that are disabled
        vm.xferClients = $filter('filter')(items.data.rows, {
          xferClientType: '!DISABLED'
        });

        _calculateStatusDisplay();

        $mdMenu.open();
      }, function failure(err) {
        console.log(err);
      });
    }

    function openHelp() {
      $state.go('guides');
    }

    function openDetail(id) {
      $state.go('xferclientDetail', {
        id: id
      });
    }

    function logout() {
      // Logging out removes any post-login redirects
      appScope.setSavedLocation(undefined); // Go back to login

      $state.go('login', {});
      loginService.logout().then(function success() {
        /* If everything goes right, we don't care. */
      }, function error(err) {
        // If something breaks, we should hear about it, but on the DL
        console.log(err);
      });
    }

    function openAccountSwitcher(readOnly) {
      if (readOnly === undefined) {
        readOnly = false;
      }

      var dialog = $mdDialog.show({
        controller: 'AccountSwitchDialogController',
        controllerAs: 'vm',
        template: require('./accountSwitcher-template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: !readOnly,
        fullscreen: true,
        locals: {
          preventClose: readOnly
        }
      });
      dialog.then(function success(properties) {
        // Redirect user to dashboard if regular user, submit page if lite user
        // set inherit to false, so that old params don't follow us like a bad disease
        if ($state.current.url !== '/eula') {
          var routeState = loginService.getDefaultRoute().state;
          $state.go(routeState, {}, {
            reload: true,
            inherit: false
          });
        }
      }, function cancel() {// Probably do nothing
      });
    }

    function toggleLogo() {
      return bowser.msie || bowser.msedge;
    }

    function labelStatus(statusString) {
      for (var i = 0; i < vm.statusArray.length; i++) {
        if (vm.statusArray[i].status === statusString) {
          return vm.statusArray[i].label;
        }
      }

      return '';
    }

    function displayTime(time) {
      return time ? moment.tz(time, moment.tz.guess()).format('MM/DD/YYYY h:mm A z') : '';
    }

    function openSupport() {
      $window.open('https://support.comcasttechnologysolutions.com/', '_blank');
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them


    function _calculateStatusDisplay() {
      var foundOnline = 0;
      var foundOffline = 0;
      var foundNoStatus = 0;
      vm.displayStatus = 'nostatus';

      if (vm.xferClients) {
        for (var i = 0; i < vm.xferClients.length; i++) {
          if (vm.xferClients[i].xferClientStatus && vm.xferClients[i].xferClientStatus === 'online') {
            foundOnline++;
          } else if (vm.xferClients[i].xferClientStatus && vm.xferClients[i].xferClientStatus === 'offline') {
            foundOffline++;
          } else if (vm.xferClients[i].xferClientStatus === null || vm.xferClients[i].xferClientStatus === 'notregistered' || vm.xferClients[i].xferClientStatus === 'noconfig') {
            foundNoStatus++;
          }
        }

        if (foundNoStatus > 0) {
          vm.displayStatus = 'nostatus';
        } else if (vm.xferClients.length === foundOnline) {
          vm.displayStatus = 'online';
        } else if (vm.xferClients.length === foundOffline) {
          vm.displayStatus = 'offline';
        } else {
          vm.displayStatus = 'mixed';
        }
      }
    }

    function _setBrandingHeader() {
      var text = ''; // If they have a branding entry, then use that.

      if (loginService.brandInfo().liteBranding && loginService.brandInfo().liteBranding.displayHeader) {
        text = loginService.brandInfo().liteBranding.displayHeader;
      } else {
        switch (vm.accountType) {
          case vm.authEnums.accountTypes.provider:
            text = 'Provider Portal';
            break;

          case vm.authEnums.accountTypes.receiver:
            text = 'Receiver Portal';
            break;

          case vm.authEnums.accountTypes.fulfillment:
            text = 'Fulfillment Portal';
            break;

          case vm.authEnums.accountTypes.qcvendor:
            text = 'QC Vendor Portal';
            break;

          case vm.authEnums.accountTypes.prodsvcs:
            text = 'Production Services Portal';
            break;

          default:
            text = 'Spotlight Portal';
        }
      }

      if (loginService.brandLogo() && loginService.brandLogo() !== '') {
        vm.brandLogo = loginService.brandLogo();
        vm.displayBrandInfo = true;
      }

      return text;
    }
    /**
     * Initialization for this controller.
     */


    function _init() {
      //TODO: Initialize this controller
      $timeout(function () {
        if (!loginService.getSessionData().userType) {
          BrandingResourceFactory.getLite({
            override: vm.destinationType
          }, function (brand) {
            loginService.brandInfo(brand);
            vm.displayHeader = _setBrandingHeader();
            sessionData.userType = loginService.brandInfo().liteBranding && loginService.brandInfo().liteBranding.liteType ? loginService.brandInfo().liteBranding.liteType : 'SPOTLIGHT';
            vm.userType = sessionData.userType;
            loginService.setSessionData(sessionData);
          });
        } else {
          vm.userType = loginService.getSessionData().userType;
          vm.displayHeader = _setBrandingHeader();
        }

        XferClientResourceFactory.getAll({
          accountId: sessionData.accountId
        }, {}, function success(items) {
          // ignore any that are disabled
          vm.xferClients = $filter('filter')(items.data.rows, {
            xferClientType: '!DISABLED'
          });

          _calculateStatusDisplay();
        }, function failure(err) {
          console.log(err);
        });
      });
    }
  }]).controller('AccountSwitchDialogController', ['$state', '$mdDialog', '$window', 'loginService', 'AuthenticationResourceFactory', 'BrandingResourceFactory', 'SecurityAccountResourceFactory', 'preventClose', function ($state, $mdDialog, $window, loginService, AuthenticationResourceFactory, BrandingResourceFactory, SecurityAccountResourceFactory, preventClose) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    var sessionData = loginService.getSessionData();
    /* BINDABLE : DATA */

    vm.accountTypes = [{
      label: 'Provider',
      value: 'PROVIDER'
    }, {
      label: 'Receiver',
      value: 'RECEIVER'
    }, {
      label: 'Fulfillment',
      value: 'FULFILLMENT'
    }, {
      label: 'QC Vendor',
      value: 'QC_VENDOR'
    }, {
      label: 'Production Services Vendor',
      value: 'PRODUCTION_SERVICES_VENDOR'
    }];
    vm.destinationTypes = [];
    vm.selectedAccount = null;
    vm.destinationType = '';
    vm.currentAccount = sessionData ? sessionData.accountName : 'Not Selected';
    vm.preventClose = preventClose;
    /* BINDABLE : METHODS*/

    vm.cancel = cancel;
    vm.switchAccount = switchAccount;
    vm.searchAccounts = searchAccounts;
    vm.selectAccount = selectAccount;
    vm.quickSelect = quickSelect;
    vm.disableSave = disableSave;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function cancel() {
      $mdDialog.cancel();
    }

    function disableSave() {
      return !vm.selectedAccount;
    }

    function switchAccount() {
      loginService.clearBrandOnSwitch();
      vm.loading = true; // For the love of all that is holy, please stop sending user lists

      delete vm.selectedAccount.Users;
      AuthenticationResourceFactory.switchAccount({}, {
        account: vm.selectedAccount,
        overrideDest: vm.destinationType
      }, function (switchObj) {
        /*SUCCESS*/
        var overrideDestType = switchObj.data.account.expressFlag ? {
          override: vm.destinationType
        } : {};
        BrandingResourceFactory.getLite(overrideDestType, function (brand) {
          //success
          loginService.brandInfo(brand);
          sessionData.userType = loginService.brandInfo().liteBranding && loginService.brandInfo().liteBranding.liteType ? loginService.brandInfo().liteBranding.liteType : 'SPOTLIGHT';
          loginService.setSessionData(sessionData);
          switchObj.data.account.destinationType = vm.selectedAccount.destinationType = vm.destinationType;
          loginService.switchAccount(switchObj.data.token, switchObj.data.account).then(function succeeded() {
            vm.loading = false;
            $mdDialog.hide({
              expressFlag: switchObj.data.account.expressFlag,
              accountType: switchObj.data.account.accountType
            });
          });
        }, function noBrand(errorReturned) {
          vm.loading = false;
          console.log(errorReturned);
        });
      }, function (err) {
        /*FAILURE*/
        vm.loading = false;
        $mdDialog.cancel('Account Not Switched');
      });
    }

    function searchAccounts() {
      vm.selectedAccount = null; // clear out any lingering search params that may have been emptied out

      if (vm.search) {
        vm.search.accountName = vm.search.accountName === '' || vm.search.accountName === undefined ? null : vm.search.accountName;
        vm.search.accountType = vm.search.accountType === '' || vm.search.accountType === undefined ? null : vm.search.accountType;
        vm.search.email = vm.search.email === '' || vm.search.email === undefined ? null : vm.search.email;
        vm.search.enabledFlag = true;
      }

      SecurityAccountResourceFactory.getAll(vm.search, function success(accounts) {
        vm.searchResults = accounts.data.rows;
      }, function failure() {
        vm.searchResults = [];
      });
    }

    function selectAccount(account) {
      vm.selectedAccount = account;
    }

    function quickSelect(account) {
      vm.selectAccount(account);
      vm.switchAccount();
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      //TODO: Initialize this controller
      vm.loading = false;
      BrandingResourceFactory.getUrls({}, {}, function success(urls) {
        vm.destinationTypes = urls;

        for (var i = 0; i < urls.length; i++) {
          if (urls[i].liteType === 'SPOTLIGHT') {
            vm.destinationType = urls[i].url;
            break;
          }
        }
      }, function failure(err) {
        console.log(err);
      });
    }
  }]);
})();