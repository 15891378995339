/**
 * Created by srober225 on 1/24/17.
 */
import { guidesController } from '../../modules/pages/controllers/guides/guides-controller';
var dependencies = ['comcast.common.authentication', 'comcast-addelivery-constants', 'ui.router', 'ngMaterial', 'ngCookies', 'comcast.common.notification', 'comcast.common.communication', 'comcast.dashboard'];
var defaultName = angular.module('comcast.common.navigation', dependencies).component('guidesController', guidesController).config(['$stateProvider', 'addelivery.state.constants', function ($stateProvider, stateConstants) {
  $stateProvider.state('guides', {
    url: '/guides',
    views: {
      mainNav: stateConstants.mainNav,
      leftNav: stateConstants.leftNav,
      content: {
        template: '<guides-controller class="guide-content"></guides-controller>'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  });
}]).name;
export { defaultName as default, dependencies };